<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- Operación tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ t("Manuals.operation") }}</span>
      </template>
      <manuals-operation-vue />
    </b-tab>
    <!--/ Operación tab -->

    <!-- Operación tab -->
    <!--
            <b-tab>
            <template #title>
                <feather-icon icon="ShieldIcon" size="18" class="mr-50" />
                <span class="font-weight-bold">{{ t('Manuals.integration') }}</span>
            </template>
        </b-tab>-->
    <!--/ Operación tab -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import ManualsIntegrationVue from "./ManualsIntegration.vue";
import ManualsOperationVue from "./ManualsOperation.vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default {
  components: {
    BTabs,
    BTab,
    ManualsIntegrationVue,
    ManualsOperationVue,
  },
  setup() {
    /** i18n utils */
    const { t } = useI18nUtils();

    return {
      t,
    };
  },
  data() {
    return {
      options: {},
    };
  },
  beforeCreate() {},
};
</script>
