<template>
    <b-card img-right :title="t('Manuals.integration')">
        
    </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
    components: {
        BCard
    },
    setup() {
        /** i18n utils */
        const { t } = useI18nUtils()

        return {
            t,
        }
    },
    data() {
        return {
            options: {},
        }
    },
    beforeCreate() {
    }
}
</script>