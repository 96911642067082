<template>
  <b-card :title="t('Manuals.operation')" class="text-center" border-variant="">
    <div class="container">
      <div class="row justify-content-md-center">
        <!-- CARD START -->
        <div class="col-md-4">
          <b-card
            :title="t('downloadpdf.setting.header')"
            class="text-center mr-8"
            border-variant="primary"
            body-bg-variant="dark-lighten-1"
          >
            <div class="mx-right">
              <div class="mb-2">
                <feather-icon icon="SettingsIcon" size="45" class="mr-50" />
              </div>

              <b-button
                href="/docs/fg_65c2fc7f-1801-2024-v1-2-settings.pdf"
                :download="t('downloadpdf.setting.header') + '.pdf'"
                variant="primary"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                >{{ t("downloadpdf") }}</b-button
              >
            </div>
          </b-card>
        </div>
        <div class="col-md-4">
          <b-card
            :title="t('downloadpdf.client.header')"
            class="text-center mr-8"
            border-variant="primary"
            body-bg-variant="dark-lighten-1"
          >
            <div class="mx-right">
              <div class="mb-2">
                <feather-icon icon="UserIcon" size="45" class="mr-50" />
                <!-- <b-img center src="../../../assets/images/icons/pdf.png" /> -->
              </div>

              <b-button
                href="/docs/fg_65c2fc7f-1203-2023-v101-clients.pdf"
                :download="t('downloadpdf.client.header') + '.pdf'"
                variant="primary"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                >{{ t("downloadpdf") }}</b-button
              >
            </div>
          </b-card>
        </div>
        <div class="col-md-4">
          <b-card
            :title="t('downloadpdf.product.header')"
            class="text-center mr-8"
            border-variant="primary"
            body-bg-variant="dark-lighten-1"
          >
            <div class="mx-right">
              <div class="mb-2">
                <feather-icon icon="PackageIcon" size="45" class="mr-50" />
              </div>

              <b-button
                href="/docs/fg_65c2fc7f-0201-2023-v101-products.pdf"
                :download="t('downloadpdf.product.header') + '.pdf'"
                variant="primary"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                >{{ t("downloadpdf") }}</b-button
              >
            </div>
          </b-card>
        </div>
        <!-- CARD END -->
      </div>
    </div>
    <div class="container">
      <div class="row justify-content-md-left">
        <!-- CARD START -->
        <div class="col-md-4">
          <b-card
            :title="t('downloadpdf.iedu.header')"
            class="text-center mr-8"
            border-variant="primary"
            body-bg-variant="dark-lighten-1"
          >
            <div class="mx-right">
              <div class="mb-2">
                <feather-icon icon="BookIcon" size="45" class="mr-50" />
                <!-- <b-img center src="../../../assets/images/icons/pdf.png" /> -->
              </div>

              <b-button
                href="/docs/fg_65c2fc7f-0201-2023-v101-iedu.pdf"
                :download="t('downloadpdf.iedu.header') + '.pdf'"
                variant="primary"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                >{{ t("downloadpdf") }}</b-button
              >
            </div>
          </b-card>
        </div>
        <div class="col-md-4">
          <b-card
            :title="t('downloadpdf.cancpago.header')"
            class="text-center mr-8"
            border-variant="primary"
            body-bg-variant="dark-lighten-1"
          >
            <div class="mx-right">
              <div class="mb-2">
                <feather-icon icon="DollarSignIcon" size="45" class="mr-50" />
                <!-- <b-img center src="../../../assets/images/icons/pdf.png" /> -->
              </div>

              <b-button
                href="/docs/fg_65c2fc7f-0208-2024-v200-canc_cpago.pdf"
                :download="t('downloadpdf.cancpago.header') + '.pdf'"
                variant="primary"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                >{{ t("downloadpdf") }}</b-button
              >
            </div>
          </b-card>
        </div>        
        <!-- CARD END -->
      </div>      
    </div>    
  </b-card>
</template>

<script>
import { BCard, BButton, BImg } from "bootstrap-vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BButton,
    BImg,
  },
  directives: {
    Ripple,
  },
  setup() {
    /** i18n utils */
    const { t } = useI18nUtils();

    return {
      t,
    };
  },
  data() {
    return {
      options: {},
    };
  },
  beforeCreate() {},
};
</script>
